// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/landing/Consultation/BackgroundEllipse.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#consultation{align-items:flex-start}#consultation h1{background-position:right}#consultation .form{display:flex;flex-direction:row;justify-content:space-between;width:100%}#consultation .form .section-wrapper{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:contain;background-position:right;width:100%;display:flex;flex-direction:row;justify-content:space-between}#consultation .form .section-wrapper .right-section{width:50%;display:flex;flex-direction:column;justify-content:center;align-items:center}#consultation .form .section-wrapper .right-section .inputs{width:100%;display:flex;flex-direction:column}", ""]);
// Exports
module.exports = exports;
