import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import Api from '../../lib/api'
import notifications from '../../lib/notifications'

export default function* watcherSaga() {
  yield takeLatest(actions.CONSULTATION_CREATE_REQUEST, watchResourceCreateRequest)
}

function* watchResourceCreateRequest({ payload }) {
  try {
    const response = yield call(Api.Consultation.create, payload)
    yield put(actions.consultationCreateSuccess(response))
    notifications.createNotification('create-success')
  } catch (error) {
    yield put(actions.consultationCreateFailure(error))
    notifications.createNotification('error')
  }
}
