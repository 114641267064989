import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { CashTurnover, TotalOrders, Filters, SalesByCategory, BaristaEfficiency, VendorComparison } from './'
import { Text, colors } from '../UIkit'

import './styles.scss'

const Dashboard = ({
  network,
  activeFilter,
  setActiveFilter,
  cashTurnover,
  cashTurnoverFecthing,
  totalOrders,
  totalOrdersFetching,
  salesByCategory,
  salesByCategoryFetching,
  baristaEfficiency,
  baristaEfficiencyFetching,
  vendorComparison,
  vendorComparisonFetching,
}) => {
  const { t } = useTranslation()

  const dashboardFooter = () => (
    <>
      <BaristaEfficiency baristaEfficiency={baristaEfficiency} loading={baristaEfficiencyFetching} />

      <VendorComparison vendorComparison={vendorComparison} loading={vendorComparisonFetching} />
    </>
  )

  return (
    <div className='dashboard'>
      <div className='dashboard__title'>
        <Text variant='h3-medium'>{t('dashboard:title')}</Text>
        <div className='dashboard__title__right-content'>
          {!isMobile &&
            <Text className='dashboard__title__right-text' color={colors.gray[500]} variant='body-tertiary1'>
              {t('dashboard:dateRange')}
            </Text>
          }
          <Filters activeFilter={activeFilter} setActiveFilter={setActiveFilter} network={network}/>
        </div>
      </div>
      <div className={`${isMobile && 'dashboard__cashturnover'}`}>
        <CashTurnover cashTurnover={cashTurnover} loading={cashTurnoverFecthing}/>
      </div>

      <div className='dashboard__row'>
        <TotalOrders totalOrders={totalOrders} loading={totalOrdersFetching}/>

        <SalesByCategory salesByCategory={salesByCategory} loading={salesByCategoryFetching}/>

        {isMobile && dashboardFooter()}
      </div>

      {!isMobile && dashboardFooter()}
    </div>
  )
}

Dashboard.propTypes = {
  network: PropTypes.object.isRequired,
  activeFilter: PropTypes.object.isRequired,
  setActiveFilter: PropTypes.func.isRequired,
  cashTurnover: PropTypes.array.isRequired,
  cashTurnoverFecthing: PropTypes.bool.isRequired,
  totalOrders: PropTypes.array.isRequired,
  totalOrdersFetching: PropTypes.bool.isRequired,
  baristaEfficiency: PropTypes.array.isRequired,
  baristaEfficiencyFetching: PropTypes.bool.isRequired,
  vendorComparison: PropTypes.array.isRequired,
  vendorComparisonFetching: PropTypes.bool.isRequired,
}

export default Dashboard
