import {
  CONSULTATION_CREATE_SUCCESS,
  CONSULTATION_CREATE_REQUEST,
  CONSULTATION_CREATE_FAILURE
} from './actions'

const initialState = {
  fetching: false,
}

export default (state = initialState, { type }) => {
  switch (type) {
    case CONSULTATION_CREATE_REQUEST:
      return { ...state, fetching: true }
    case CONSULTATION_CREATE_SUCCESS:
      return { ...state, fetching: false }
    case CONSULTATION_CREATE_FAILURE:
      return { ...state, fetching: false }
    default:
      return state
  }
}
