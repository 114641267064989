import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ReactHintFactory from 'react-hint'

import { Modal, Button, Input, TextArea, Switch } from '../UIkit'
import { Uploader } from '../Uploader'
import { Validator } from '../../lib'
import { handleValidate } from '../../lib/validationHelper'
import { HintIcon } from '../UIkit/svgs'

const NetworkUpdate = ({ isOpen, handleClose, network, updateNetwork }) => {
  const { t } = useTranslation()

  const [form, setForm] = useState({})

  const [formErrors, setFormErrors] = useState({
    url: '',
  })

  const validationFields = [['url', Validator.concepts.isUrl, [form.url]]]

  const ReactHint = ReactHintFactory(React)

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  useEffect(() => {
    setForm({
      id: network.id,
      name: network.name,
      url: network.url,
      description: network.description,
      logo: network.logo?.signedId,
      loyalty: network.loyalty,
      checkboxIntegrated: network.checkboxIntegrated
    })
  }, [network])

  const handleSubmit = () => {
    handleValidate(validationFields)
      .then(() => {
        updateNetwork(form), handleClose()
      })
      .catch((errors) => setFormErrors(errors))
  }

  const handleUpload = (signedId) => {
    setForm((prev) => ({ ...prev, logo: signedId }))
  }

  const handleDeleteImage = () => {
    setForm((prev) => ({ ...prev, logo: null }))
  }

  const handleCheckLoyaltyConnected = () => {
    setForm((prev) => ({ ...prev, loyalty: !form.loyalty }))
  }

  const handleChangeCheckboxIntegrated = () => {
    setForm((prev) => ({ ...prev, checkboxIntegrated: !form.checkboxIntegrated }))
  }

  return (
    <Modal show={isOpen} handleClose={handleClose} title={t('network:edit')}>
      <div className='network-modal'>
        <div className='update-network-form'>
          <Input
            id='network-name'
            name='name'
            placeholder={t('input:networkName')}
            value={form.name}
            onChange={handleUpdateField}
          />

          <Input
            id='website'
            name='url'
            placeholder={t('input:website')}
            value={form.url}
            onChange={handleUpdateField}
            error={formErrors.url}
          />

          <TextArea
            id='about'
            placeholder={t('input:about')}
            name='description'
            rows={7}
            value={form.description}
            onChange={handleUpdateField}
          />

          <div className='network-switch'>
            <Switch
              index={1}
              label={t('input:joinLoyalty')}
              checked={form.loyalty}
              onChange={handleCheckLoyaltyConnected}
            />
          </div>

          <div className='network-checkbox-switch'>
            <Switch
              index={2}
              label={t('input:checkboxIntegrated')}
              checked={form.checkboxIntegrated}
              onChange={handleChangeCheckboxIntegrated}
            />
            <div className='network-checkbox-hint'>
              <ReactHint
                className='react-hint'
                events
              />
              <HintIcon data-rh={t('hint:checkboxIntegration')} />
            </div>
          </div>
        </div>

        <Uploader name='logo' onChange={handleUpload} onDelete={handleDeleteImage} image={network.logo}/>
      </div>

      <div className='network-modal-footer'>
        <Button type='submit' disabled={form.name == ''} onClick={handleSubmit}>
          {t('btn:save')}
        </Button>
      </div>
    </Modal>
  )
}

NetworkUpdate.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  network: PropTypes.object.isRequired,
  updateNetwork: PropTypes.func.isRequired,
}

export default NetworkUpdate
