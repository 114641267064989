import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Text, Button, colors, IconButton } from '../UIkit'
import { Plus, RightArrow } from '../UIkit/svgs'
import { NETWORK_TABS } from '../../constants'
import { NetworkUpdate, Details, Vendors, Tabs, VendorCreate, Map } from '.'

import './styles.scss'
import AddButton from '../UIkit/AddButton'

const NetworkInfo = ({ network, vendors, updateNetwork, createVendor, deleteVendor, vendorsFetching }) => {
  const { t } = useTranslation()

  const [tab, setTab] = useState(NETWORK_TABS.details)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isMapOpen, setIsMapOpen] = useState(false)
  const [isVendorCreateOpen, setVendorCreateOpen] = useState(false)
  const [vendorCoordinates, setVendorCoordinates] = useState({})

  const handleToggleModal = () => setModalOpen((prev) => !prev)
  const handleOpenCreate = () => setVendorCreateOpen((prev) => !prev)

  const handleClose = () => {
    setIsMapOpen(false)
  }

  const handleOpen = () => {
    isMobile && setIsMapOpen(true)
  }

  const renderTab = () => {
    switch (tab) {
      case 'details':
        return <Details network={network} handleToggleModal={handleToggleModal} />
      case 'vendors':
        return (
          <Vendors
            network={network}
            vendors={vendors}
            deleteVendor={deleteVendor}
            setVendorCoordinates={setVendorCoordinates}
            vendorsFetching={vendorsFetching}
          />
        )
    }
  }

  const handleToggle = (tab) => () => setTab(tab)

  return (
    <>
      <div className='title'>
        <Text variant='h3-medium'>{t('network:title')}</Text>

        {!isMobile ? 
          <Button style={{ minWidth: 204 }} onClick={handleOpenCreate}>
            <Plus />
            {t('network:addVendor')}
          </Button> :
          <div className='title__content' onClick={handleOpen}>
            <Text color={colors.primary[500]} variant='body-main2'>{t('network:map')}</Text>
            <RightArrow color={colors.primary[500]} width={20} height={15} />
          </div>
        }
      </div>

      <div className='network'>
        {!isMobile && <Map vendors={vendors} vendorCoordinates={vendorCoordinates} />}

        <div className='network-info'>
          <Tabs tab={tab} handleToggle={handleToggle} />

          {renderTab()}
        </div>
      </div>

      <VendorCreate isShown={isVendorCreateOpen} network={network} closeModal={handleOpenCreate} createVendor={createVendor} />

      <NetworkUpdate
        isOpen={isModalOpen}
        handleClose={handleToggleModal}
        network={network}
        updateNetwork={updateNetwork}
      />
      {isMapOpen && 
        <div className='network-map'>
          <IconButton onClick={handleClose} >
            <RightArrow height={20} width={20} />
          </IconButton>
          <Map vendors={vendors} vendorCoordinates={vendorCoordinates} />
        </div> 
      }
      {isMobile && !isMapOpen && !isVendorCreateOpen &&           
        <AddButton onClick={handleOpenCreate}>
          <Plus color='white' />
        </AddButton>
      }
    </>
  )
}

NetworkInfo.propTypes = {
  network: PropTypes.object.isRequired,
  updateNetwork: PropTypes.func.isRequired,
  createVendor: PropTypes.func.isRequired,
  deleteVendor: PropTypes.func.isRequired,
  vendorsFetching: PropTypes.bool.isRequired,
}

export default NetworkInfo
