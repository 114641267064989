import React from 'react'
import moment from 'moment'

import { Switch } from '../../../UIkit'
import InfoRow from '../../components/InfoRow'
import { staffNetworksUpdateRequest } from '../../../../store/staff/networks/actions'
import { useDispatch } from 'react-redux'

const NetworkInfo = ({ network }) => {
  const dispatch = useDispatch()

  const handleUpdateStatus = () => {
    const status = network.status === 'active' ? 'hidden' : 'active'
    dispatch(staffNetworksUpdateRequest({ id: network.id, params: { status } }))
  }

  return (
    <div className='staff-info-block'>
      <InfoRow title='Description' text={network.description} />
      <InfoRow title='Url' text={network.url} />
      <InfoRow title='Creation date' text={moment(network.createdAt).format('DD-MM-YYYY')} />
      <InfoRow title='Hidden'>
        <Switch index={0} checked={network.status === 'hidden'} onChange={() => handleUpdateStatus()} />
      </InfoRow>
    </div>
  )
}

export default NetworkInfo
