import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFilters } from '../../contexts/filtersContext'
import { useRowsPerPage } from '../../contexts/perPageContext'
import {
  employeesGetRequest,
  employeeCreateRequest,
  employeeUpdateRequest,
  employeesClear,
} from '../../store/employees/actions'
import EmployeesOptions from '../../components/Employees/EmployeesOptions'
import EmployeesPage from '../../components/Employees/List/EmployeesPage'
import { Text, Loader, Pagination, EmptyPageMessage } from '../../components/UIkit'
import './styles.scss'

const EmployeesContainer = () => {
  const [page, setPage] = useState(1)

  const { cityFilter, vendorFilter } = useFilters()

  const { rowsPerPage, setRowsPerPage } = useRowsPerPage()

  const { items: employees, fetching, pages: totalPages } = useSelector((state) => state.employees)

  const network = useSelector((state) => state.networks.network)

  const { addresses } = useSelector((state) => state.vendors)

  const networkId = localStorage.getItem('networkId')

  const dispatch = useDispatch()

  const history = useNavigate()

  const { t } = useTranslation()

  const navigateToNetwork = () => {
    history('/network')
  }

  const getEmployees = (params) => {
    dispatch(employeesGetRequest({ params: params }))
  }

  const createEmployee = (data, callback) => dispatch(employeeCreateRequest({ data, callback }))

  const updateEmployee = (data) => dispatch(employeeUpdateRequest(data))

  const clearEmployees = () => dispatch(employeesClear())

  const handleChangePage = (page) => {
    if (page <= totalPages) {
      setPage(page)
      if (page) getEmployees({ page: page, per: rowsPerPage, city: cityFilter, vendorId: vendorFilter })
    }
  }

  useEffect(() => {
    setPage(1)
  }, [networkId, cityFilter, vendorFilter, rowsPerPage])

  useEffect(() => {
    getEmployees({ page: 1, per: rowsPerPage, city: cityFilter, vendorId: vendorFilter })

    return () => {
      clearEmployees()
    }
  }, [networkId, cityFilter, vendorFilter, rowsPerPage])

  return (
    <>
      <div className='employees'>
        <div className='employees__title'>
          <Text variant='h3-medium'>{t('employees:title')}</Text>
          <EmployeesOptions
            network={network}
            createEmployee={createEmployee}
            vendorsList={addresses}
            navigateToNetwork={navigateToNetwork}
          />
        </div>
        {fetching ? (
          <Loader size={54} />
        ) : employees.length ? (
          <EmployeesPage employees={employees} network={network} updateEmployee={updateEmployee} />
        ) : (
          <EmptyPageMessage text={t('employees:empty')} />
        )}
        <Pagination
          activePage={page}
          perPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangePerPage={setRowsPerPage}
          totalPages={totalPages}
          isShow={employees.length}
        />
      </div>
    </>
  )
}

export default React.memo(EmployeesContainer)
