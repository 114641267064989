/* eslint-disable react/display-name */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../components/UIkit'

export const tourSteps = () => {
  const { t } = useTranslation()

  const steps = [
    {
      selector: '[data_tut="dashboard-tutorial-1"]',
      content: () => (
        <div>
          <Text variant='body-main2'>{t('tutorial:dashboard:title')}</Text>
          <br/>
          <Text variant='body-secondary1'>{t('tutorial:dashboard:textFirstOne')}</Text>
          <Text variant='body-secondary1'>{t('tutorial:dashboard:textFirstTwo')}</Text>
        </div>
      )
    },
    {
      selector: '[data_tut="dashboard-tutorial-2"]',
      content: () => (
        <div>
          <Text variant='body-main2'>{t('tutorial:dashboard:title')}</Text>
          <br/>
          <Text variant='body-secondary1'>{t('tutorial:dashboard:textSecond')}</Text>
        </div>
      )
    },
    {
      selector: '[data_tut="dashboard-tutorial-3"]',
      content: () => (
        <div>
          <Text variant='body-main2'>{t('tutorial:dashboard:title')}</Text>
          <br/>
          <Text variant='body-secondary1'>{t('tutorial:dashboard:textThird')}</Text>
        </div>
      )
    }
  ]

  return { steps: steps, stepsLength: steps.length }
}
