import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  DashboardFilled,
  Dashboard,
  CartFilled,
  Cart,
  Coffee,
  CoffeeFilled,
  UsersFilled,
  Users,
  MenuFilled,
  Menu,
} from '../../components/UIkit/svgs'
import { colors } from '../../components/UIkit'
import './styles.scss'
import { isMobile } from 'react-device-detect'

const BottomBarContainer = () => {

  const BottomBarLink = ({ to, children }) => (
    <NavLink
      className='bottom-bar__item'
      to={to}
      children={children}
    />
  )

  return (
    <div className='bottom-bar'>
      <div>
        <BottomBarLink
          to='dashboard'
          children={({ isActive }) => {
            return isActive ? <DashboardFilled color={colors.primary[500]} /> : <Dashboard color='#71667F' />
          }}
        />
      </div>
      <div>
        <BottomBarLink
          to='orders'
          children={({ isActive }) => {
            return isActive ? <CartFilled color={colors.primary[500]} /> : <Cart color='#71667F' />
          }}
        />
      </div>
      <div data_tut={isMobile ? 'dashboard-tutorial-1' : null}>
        <BottomBarLink
          to='network'
          children={({ isActive }) => {
            return isActive ? <CoffeeFilled color={colors.primary[500]} /> : <Coffee color='#71667F' />
          }}
        />
      </div>
      <div data_tut={isMobile ? 'dashboard-tutorial-3' : null}>
        <BottomBarLink
          to='employees'
          children={({ isActive }) => {
            return isActive ? <UsersFilled color={colors.primary[500]} /> : <Users color='#71667F' />
          }}
        />
      </div>
      <div data_tut={isMobile ? 'dashboard-tutorial-2' : null}>
        <BottomBarLink
          to='menu'
          children={({ isActive }) => {
            return isActive ? <MenuFilled color={colors.primary[500]} /> : <Menu color='#71667F' />
          }}
        />
      </div>
    </div>
  )
}

export default React.memo(BottomBarContainer)
