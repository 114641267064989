import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { Modal, Button, Dropdown, Text, Input, colors } from '../../UIkit'
import Validator from '../../../lib/validator'
import { formatPhoneNumber } from '../../../lib'

const EmployeeCreate = ({ createEmployee, vendorsList, isShown, closeModal }) => {
  const { t } = useTranslation()

  const JOB_ROLES = [
    { value: 'barista', label: t('employees:barista') }
  ]

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    passwordConfirmation: '',
    networkId: Number(localStorage.getItem('networkId')),
    jobRole: JOB_ROLES[0].value,
    vendorId: vendorsList[0]?.value,
  })

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '+380 (',
    password: '',
    passwordConfirmation: ''
  })

  const handleValidate = () =>
    new Promise((resolve, reject) => {
      const errors = Validator.validate([
        ['firstName', Validator.concepts.isFilled, [form.firstName]],
        ['lastName', Validator.concepts.isFilled, [form.lastName]],
        ['email', Validator.concepts.emailRegex, [form.email]],
        ['phone', Validator.concepts.phoneRegex, [form.phone]],
        ['password', Validator.concepts.isLongerThan, [6, form.password]],
        ['passwordConfirmation', Validator.concepts.isLongerThan, [6, form.passwordConfirmation]],
        ['passwordConfirmation', Validator.concepts.isMatching, [form.password, form.passwordConfirmation]],
      ])

      Validator.clear()

      isEmpty(errors) ? resolve() : reject(errors)
    })

  const handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      networkEmployee: {
        networkId: form.networkId,
        role: form.jobRole,
        vendorId: form.vendorId,
        phone_number: form.phone,
        email: form.email,
        password: form.password,
        passwordConfirmation: form.passwordConfirmation,
        profileAttributes: {
          firstName: form.firstName,
          lastName: form.lastName,
        },
      },
    }

    handleValidate()
      .then(() => {
        createEmployee(data, closeModal)
      })
      .catch((errors) => setFormErrors(errors))
  }

  const handleUpdateField = ({ target }) => setForm((prev) => ({ ...prev, [target.name]: target.value }))

  const handleUpdatePhone = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: formatPhoneNumber(target.value) }))
  }

  const handleUpdateVendor = useCallback((item) => setForm((prev) => ({ ...prev, vendorId: item })), [setForm])

  return (
    <Modal show={isShown} handleClose={closeModal} title={t('employees:addNewEmployee')} scrollable={true}>
      <form className='create-employee' onSubmit={handleSubmit}>
        <div className='create-employee__info-block'>
          <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
            {t('employees:contacts')}
          </Text>
          <div className='create-employee__inputs'>
            <Text wrapper variant='body-main1'>
              <Input
                id='first-name'
                name='firstName'
                placeholder={t('input:firstName')}
                autoFocus
                value={form.firstName}
                onChange={handleUpdateField}
                error={formErrors.firstName}
              />
            </Text>
            <Text wrapper variant='body-main1'>
              <Input
                id='last-name'
                name='lastName'
                placeholder={t('input:lastName')}
                value={form.lastName}
                onChange={handleUpdateField}
                error={formErrors.lastName}
              />
            </Text>
            <Text wrapper variant='body-main1'>
              <Input
                id='email'
                name='email'
                type='email'
                autoComplete='new-password'
                placeholder={t('input:email')}
                value={form.email}
                onChange={handleUpdateField}
                error={formErrors.email}
              />
            </Text>
            <Text wrapper variant='body-main1'>
              <Input
                id='password'
                name='password'
                type='password'
                autoComplete='new-password'
                placeholder={t('input:password')}
                value={form.password}
                onChange={handleUpdateField}
                error={formErrors.password}
              />
            </Text>
            <Text wrapper variant='body-main1'>
              <Input
                id='passwordConfirmation'
                name='passwordConfirmation'
                type='password'
                placeholder={t('input:passwordConfirmation')}
                value={form.passwordConfirmation}
                onChange={handleUpdateField}
                error={formErrors.passwordConfirmation}
              />
            </Text>
            <Text wrapper variant='body-main1'>
              <Input
                id='phone'
                name='phone'
                type='tel'
                placeholder={t('input:phoneNumber')}
                value={form.phone}
                onChange={handleUpdatePhone}
                error={formErrors.phone}
              />
            </Text>
          </div>
        </div>
        <div className='create-employee__info-block'>
          <Text shouldBeUppercase variant='body-tertiary1' color={colors.gray[500]}>
            {t('employees:position')}
          </Text>
          <div className='create-employee__selects'>
            <Text wrapper variant='body-main1'>
              <Dropdown
                placeholder={t('input:vendor')}
                value={form.vendorId}
                onChange={handleUpdateVendor}
                list={vendorsList}
              />
            </Text>
          </div>
        </div>
        <div className='create-employee__button'>
          <Button type='submit' style={{ marginTop: 24 }}>
            {t('actions:invite')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EmployeeCreate
