import React from 'react'
import DashboardContainer from '../containers/Dashboard'
import NetworkContainer from '../containers/Network/NetworkContainer'
import LandingContainer from '../containers/Landing'
import LandingBusinessContainer from '../containers/LandingBusiness'
import EmployeesContainer from '../containers/Employees'
import ProductsContainer from '../containers/Products/ProductsContainer'
import MenuContainer from '../containers/Menu/TypesContainer'
import MenuProductsContrainer from '../containers/Menu/ProductsContainer'
import ResetPasswordContainer from '../containers/Auth/ResetPasswordContainer'
import EmailConfirmationContainer from '../containers/EmailConfirmation/EmailConfirmationContainer'
import TermsContainer from '../containers/TermsAndPrivacy/TermsContainer'
import PrivacyContainer from '../containers/TermsAndPrivacy/PrivacyContainer'
import OrdersContainer from '../containers/Orders'
import NotFound from '../components/NotFound/NotFound'
import SignUpContainer from '../containers/Auth/SignUpContainer'
import SignInContainer from '../containers/Auth/SignInContainer'
// import WithdrawFundsContainer from '../containers/WithdrawFunds'
import SettingsContainer from '../containers/Settings'
import DetectDevice from '../components/DetectDevice/DetectDevice.js'
import PosterContainer from '../components/Menu/Poster/PosterContainer.js'
import SignInView from '../components/Staff/SignIn/SignInView'
import OwnersView from '../components/Staff/Owners/OwnersView'
import NetworksView from '../components/Staff/Networks/NetworksView'
import NetworkView from '../components/Staff/Networks/Show/NetworkView'
import VendorView from '../components/Staff/Vendors/Show/VendorView'
import ReceiptsContainer from '../containers/Receipts/ReceiptsContainer.js'

export const RouteNames = {
  ROOT: '/',
  FOR_BUSINESS: '/business',
  DASHBOARD: '/dashboard',
  EMPLOYEES: '/employees',
  MENU_TYPES: '/menu',
  POSTER: '/poster',
  MENU_PRODUCTS: '/menu/:type',
  NETWORK: '/network',
  ORDERS: '/orders',
  PAYMENTS: '/payments',
  ADMIN: '/admin',
  PRODUCTS: '/products',
  TERMS: '/terms_and_conditions',
  PRIVACY: '/privacy_policy',
  EMAIL_CONFIRMATION: '/email_confirmation/:token',
  NOTIFICATIONS: '/notifications',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot_password',
  BARISTA_FORGOT_PASSWORD: '/barista/forgot_password',
  PASSWORD_RESET: '/password_reset/:token',
  BARISTA_PASSWORD_RESET: '/barista/password_reset/:token',
  // WITHDRAW_FUNDS: '/withdraw_funds',
  SETTINGS: '/settings',
  DETECT_DEVICE: 'detect-device',
  DETECT_DEVICE2: 'app',
  NOT_FOUND: '*',
  STAFF_LOGIN: '/staff/login',
  STAFF_SIGNIN: '/staff/signin',
  STAFF_OWNERS: '/staff/owners',
  STAFF_NETWORKS: '/staff/networks',
  STAFF_VENDORS: '/staff/vendors',
  RECEIPTS: '/receipts'
}

export const publicRoutes = [
  { path: RouteNames.DETECT_DEVICE, element: <DetectDevice /> },
  { path: RouteNames.DETECT_DEVICE2, element: <DetectDevice /> },
  { path: RouteNames.ROOT, element: <LandingContainer /> },
  { path: RouteNames.FOR_BUSINESS, element: <LandingBusinessContainer /> },
  { path: RouteNames.ADMIN, element: <SignInContainer /> },
  { path: RouteNames.SIGNUP, element: <SignUpContainer /> },
  { path: RouteNames.FORGOT_PASSWORD, element: <ResetPasswordContainer /> },
  { path: RouteNames.BARISTA_FORGOT_PASSWORD, element: <ResetPasswordContainer /> },
  { path: RouteNames.PASSWORD_RESET, element: <ResetPasswordContainer /> },
  { path: RouteNames.BARISTA_PASSWORD_RESET, element: <ResetPasswordContainer /> },
  { path: RouteNames.STAFF_LOGIN, element: <SignInView /> },
  { path: RouteNames.STAFF_SIGNIN, element: <SignInView /> },
  {
    path: RouteNames.EMAIL_CONFIRMATION,
    element: <EmailConfirmationContainer />,
  },
  { path: RouteNames.TERMS, element: <TermsContainer /> },
  { path: RouteNames.PRIVACY, element: <PrivacyContainer /> },
  { path: RouteNames.NOT_FOUND, element: <NotFound /> },
]

export const privateRoutes = [
  { path: RouteNames.DASHBOARD, element: <DashboardContainer /> },
  { path: RouteNames.EMPLOYEES, element: <EmployeesContainer /> },
  { path: RouteNames.MENU_TYPES, element: <MenuContainer /> },
  { path: RouteNames.MENU_PRODUCTS, element: <MenuProductsContrainer /> },
  { path: RouteNames.NETWORK, element: <NetworkContainer /> },
  { path: RouteNames.PRODUCTS, element: <ProductsContainer /> },
  { path: RouteNames.ORDERS, element: <OrdersContainer /> },
  // { path: RouteNames.WITHDRAW_FUNDS, element: <WithdrawFundsContainer /> },
  { path: RouteNames.SETTINGS, element: <SettingsContainer /> },
  { path: RouteNames.POSTER, element: <PosterContainer /> },
  { path: RouteNames.RECEIPTS, element: <ReceiptsContainer /> }
]

export const privateStaffRoutes = [
  { path: RouteNames.STAFF_OWNERS, element: <OwnersView /> },
  { path: RouteNames.STAFF_NETWORKS, element: <NetworksView /> },
  { path: `${RouteNames.STAFF_NETWORKS}/:id`, element: <NetworkView /> },
  { path: `${RouteNames.STAFF_VENDORS}/:id`, element: <VendorView /> },
]
