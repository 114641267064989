import React from 'react'
import { Footer, Header } from '../Landing/Sections'
import { Intro, HowItWorks, Features, Applications, AppLinks, Consultation } from './Sections'
import './styles.scss'
import './responsiveStyles.scss'

const LandingBusinessComponent = ({ createConsultation }) => {
  return (
    <>
      <Header />
      <main className='landing-main business'>
        <Intro />
        <HowItWorks />
        <Features />
        <Applications />
        <Consultation createConsultation={createConsultation} />
        <AppLinks />
        <Footer />
      </main>
    </>
  )
}

export default LandingBusinessComponent
