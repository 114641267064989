export const CONSULTATION_CREATE_REQUEST = 'CONSULTATION_CREATE_REQUEST'
export const CONSULTATION_CREATE_SUCCESS = 'CONSULTATION_CREATE_SUCCESS'
export const CONSULTATION_CREATE_FAILURE = 'CONSULTATION_CREATE_FAILURE'

export const consultationCreateRequest = (payload) => ({
  type: CONSULTATION_CREATE_REQUEST,
  payload
})

export const consultationCreateSuccess = (payload) => ({
  type: CONSULTATION_CREATE_SUCCESS,
  payload
})

export const consultationCreateFailure = (payload) => ({
  type: CONSULTATION_CREATE_FAILURE,
  payload
})
