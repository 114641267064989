import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Vendor } from '.'
import { Text, Loader, colors } from '../UIkit'

const Vendors = ({ vendors, network, deleteVendor, vendorsFetching, setVendorCoordinates }) => {
  const { t } = useTranslation()

  return (
    <div className='vendors'>
      {!vendorsFetching && (
        <>
          <div className='vendors__counter'>
            <Text variant='body-main1' color={colors.gray[500]}>
              {t('network:numberOfVendors')}:&nbsp;
            </Text>

            <Text color={colors.gray[800]} variant='body-main2'>
              {vendors.length}
            </Text>
          </div>

          {!vendors.length && (
            <Text variant='body-secondary1' color={colors.gray[500]}>
              {t('network:noVendors')}
            </Text>
          )}

          {vendors.map((vendor, i) => (
            <Vendor
              key={i}
              vendor={vendor}
              network={network}
              setVendorCoordinates={setVendorCoordinates}
              deleteVendor={deleteVendor}
            />
          ))}
        </>
      )}

      {vendorsFetching && <Loader size={54} inBlock />}
    </div>
  )
}

Vendors.propTypes = {
  vendors: PropTypes.array.isRequired,
  deleteVendor: PropTypes.func.isRequired,
  vendorsFetching: PropTypes.bool.isRequired,
  setVendorCoordinates: PropTypes.func.isRequired,
}

export default Vendors
