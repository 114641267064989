import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import capitalize from '../../../lib/capitalize'
import { colors, Modal, Text } from '../../UIkit'
import { ArrowToRight, Calendar, Check, Clock, CreditCard, Cup, Exit, Profile } from '../../UIkit/svgs'
import OrderStatus from '../../OrderStatus/OrderStatus'

const OrderInfoModal = ({ isShown, closeModal, order, fetching }) => {
  const { t } = useTranslation()

  const renderOrderStatus = (status) => {
    switch (status) {
      case 'finished':
        return (
          <OrderStatus
            statusIcon={<Check width='20' height='20' color={colors.gray[500]} />}
            className={'order-info__status finished'}
            textColor={colors.gray[500]}
            text={t('orders:finished')}
          />
        )
      case 'canceled':
        return (
          <OrderStatus
            statusIcon={<Exit width='20' height='20' color={colors.primary[500]} />}
            className={'order-info__status canceled'}
            textColor={colors.primary[500]}
            text={t('orders:canceled')}
          />
        )
      case 'new':
        return (
          <OrderStatus
            statusIcon={<ArrowToRight width='20' height='14' color={colors.secondary[500]} />}
            className={'order-info__status new'}
            textColor={colors.secondary[500]}
            text={t('orders:new')}
          />
        )
      case 'in_progress':
        return (
          <OrderStatus
            statusIcon={<Clock width='20' height='20' color={colors.secondary[500]} />}
            className={'order-info__status in_progress'}
            textColor={colors.secondary[500]}
            text={t('orders:in_progress')}
          />
        )
      case 'ready':
        return (
          <OrderStatus
            statusIcon={<Check width='20' height='20' color={colors.secondary[500]} />}
            className={'order-info__status ready'}
            textColor={colors.secondary[500]}
            text={t('orders:ready')}
          />
        )
      case 'failed':
        return (
          <OrderStatus
            statusIcon={<Exit width='20' height='20' color={colors.primary[500]} />}
            className={'order-info__status failed'}
            textColor={colors.primary[500]}
            text={t('orders:failed')}
          />
        )
      default:
        return null
    }
  }

  const renderText = (content, variant, additionalProps = {}) => (
    <Text variant={variant} {...additionalProps}>
      {content}
    </Text>
  )

  return (
    <Modal show={isShown} fetching={fetching} handleClose={closeModal}>
      <div className='order-info'>
        <div className='order-info__header'>
          {renderText(`${t('orders:order')} #${order.id}`, 'h3-bold')}
          <div className='order-info__status-bar'>
            <div className='order-info__date'>
              <Calendar />
              {renderText(order.date, 'body-main1')}
            </div>
            {renderOrderStatus(order.status)}
          </div>
        </div>
        <div className='order-info__scrollable'>
          <div className='order-info__details'>
            <div className='order-info__block'>
              <div className='order-info__block-headline'>
                <div className='order-info__block-headline-icon'>
                  <Cup color={colors.primary[500]} />
                </div>
                {renderText(t('orders:vendor'), 'subheading')}
              </div>
              <div className='order-info__block-location'>
                {renderText(`${order.vendorCity} ${t('orders:city')}`, 'body-tertiary1', { color: colors.gray[500] })}
                {renderText(order.vendorAddress, 'body-main1')}
              </div>
            </div>
            <div className='order-info__block'>
              <div className='order-info__block-headline'>
                <div className='order-info__block-headline-icon'>
                  <Profile color={colors.primary[500]} />
                </div>
                {renderText(t('orders:servicesBy'), 'subheading')}
              </div>
              <div className='order-info__block-location'>
                {renderText(t('orders:barista'), 'body-tertiary1', { color: colors.gray[500] })}
                {renderText(order.baristaFullName, 'body-main1')}
              </div>
            </div>
            <div className='order-info__block'>
              <div className='order-info__block-headline'>
                <div className='order-info__block-headline-icon'>
                  <CreditCard color={colors.primary[500]} />
                </div>
                {renderText(t('orders:payment'), 'subheading')}
              </div>
              <div className='order-info__block-location'>
                {renderText(order.cardToken?.holder, 'body-tertiary1', { color: colors.gray[500] })}
                {renderText(`${capitalize(order.cardToken?.brand)} ${order.cardToken?.number}`, 'body-main1')}
              </div>
            </div>
          </div>
          <div className='order-info__pricing'>
            <div className='order-info__pricing-list'>
              <Text variant={'h4-bold'}>{t('orders:orderDetails')}</Text>
              <div className='order-info__pricing-list-items'>
                {order.orderItems?.map((orderItem, index) => (
                  <div className='order-info__order-item' key={index}>
                    {!isMobile && renderText(`x${orderItem.count}`, 'body-main2')}
                    <div className='order-info__order-item-block'>
                      {isMobile && renderText(`x${orderItem.count}`, 'body-main2')}
                      <div
                        style={{ backgroundImage: `url(${orderItem.imageUrl})` }}
                        className='order-info__order-item-image'
                      />
                      <div className='order-info__order-item-details'>
                        <div className='order-info__order-item-details-main'>
                          {renderText(orderItem.name, 'body-main2')}
                          {!isMobile &&
                            renderText(orderItem.info, 'body-secondary1', { color: colors.gray[500] })}
                        </div>
                        {renderText(`${t('orders:uah')} ${orderItem.price}`, 'body-secondary2')}
                      </div>
                      {isMobile &&
                        <div className='order-info__order-item-price'>
                          {renderText(`${t('orders:uah')} ${orderItem.priceWithCount}`, 'body-main2')}
                        </div>}
                    </div>
                    {isMobile &&
                      renderText(orderItem.info, 'body-secondary1', { color: colors.gray[500] })}
                    {!isMobile &&
                      <div className='order-info__order-item-price'>
                        {renderText(`${t('orders:uah')} ${orderItem.priceWithCount}`, 'body-main2')}
                      </div>}
                  </div>
                ))}
              </div>
            </div>
            <div className='order-info__pricing-price-details'>
              <div className='order-info__pricing-price-details-item'>
                {renderText(t('orders:total'), 'body-main1')}
                {renderText(`${t('orders:uah')} ${order.totalWithoutDiscount}`, 'body-main2')}
              </div>
              <div className='order-info__pricing-price-details-item'>
                {renderText(t('orders:discount'), 'body-main1')}
                {renderText(order.discount ? `${order.discount}%` : '-', 'body-main2')}
              </div>
              <div className='order-info__pricing-price-details-item'>
                {renderText(t('orders:toBePaid'), 'subheading')}
                {renderText(`${t('orders:uah')} ${order.total}`, 'subheading')}
              </div>
              <div className='order-info__pricing-price-details-item'>
                {renderText(t('orders:status'), 'body-main1')}
                {renderText(t(`orders:${order.status}`), 'body-main2', { color: colors.gray[500] })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OrderInfoModal
