import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { vendorGetRequest, vendorUpdateRequest } from '../../store/vendors/actions'
import { VendorForm  } from '../../components/Network'
import { Modal } from '../../components/UIkit'

const VendorUpdateContainer = ({ isShown, network, closeModal, id }) => {
  const { t } = useTranslation()
  const networkId = localStorage.getItem('networkId')
  const dispatch = useDispatch()

  const getVendor = () => dispatch(vendorGetRequest({ networkId, id }))
  const updateVendor = (data) => dispatch(vendorUpdateRequest(data))
  const { item: vendor, itemFetching: fetching } = useSelector((state) => state.vendors)

  useEffect(() => { getVendor() }, [])

  return (
    <Modal show={isShown} handleClose={closeModal} fetching={fetching} title={t('network:editVendor')}>
      <VendorForm vendor={vendor} network={network} closeModal={closeModal} updateVendor={updateVendor} />
    </Modal>
  )
}

export default VendorUpdateContainer
