import React from 'react'

import {
  AppStoreButtonLight,
  GooglePlayButtonLight,
  Logo,
  Visa,
  Mastercard,
  Instagram,
  Facebook,
  Linkedin,
} from '../../../../assets/svgs/landing'
import Links from '../../constants'
import { PhoneLanding, EmailLanding } from '../../../UIkit/svgs'

import './styles.scss'
import './responsiveStyles.scss'
import { Link } from 'react-router-dom'

function Footer () {
  // const footerLinks = {
  //   cookies: 'https://docs.google.com/document/d/1s7YZ5U9z5p3SQ-d9UiX0IbuJZvCp8kaT/edit',
  //   rules: 'https://docs.google.com/document/d/1OYCVyAfn2n7hqj3arwPgbCgTcTviW_-or5hQhsyzjKs/edit'
  // }

  return (
    <footer className='main-footer'>
      <div className='wrapper'>
        <div className='pinka-logo'>
          <img src={Logo} alt='Pinka logo' />
        </div>

        <div className='social-media'>
          <h6>Соціальні мережі</h6>
          <div className='social-link'>
            <a href='https://www.instagram.com/pinka.app/' >
              <img src={Instagram} alt='Instagram' />
            </a>
            <a href='https://www.facebook.com/pinkacoffeeua' >
              <img src={Facebook} alt='Facebook' />
            </a>
            <a href='https://www.linkedin.com/company/pinkacoffeeua'>
              <img src={Linkedin} alt='Linkedin' />
            </a>
          </div>
        </div>
      
        <div className='download-links'>
          <h6>Завантажити</h6>
          <div className='two-buttons'>
            <a href={Links.linkToGooglePlay}>
              <img src={GooglePlayButtonLight} alt='Google play' />
            </a>
            <a href={Links.linkToAppStore}>
              <img src={AppStoreButtonLight} alt='App store' />
            </a>
          </div>
        </div>

        <div className='contacts'>
          <h6>Контакти</h6>
          <div>
            <PhoneLanding />
            <a href='tel:+38 (097) 75 35 731'>+38 (097) 75 35 731</a>
          </div>
          <div>
            <EmailLanding />
            <a href='mailto: contact@pinka.com '>contact@pinka.com </a>
          </div>
        </div>

        <div className='payments'>
          <h6>Способи оплати</h6>
          <div className='logos'>
            <img src={Mastercard} alt='Mastercard' />
            <img src={Visa} alt='Visa' />
          </div>
        </div>
      </div>

      <div className='terms'>
        <Link className='btn-business' to='/terms_and_conditions'>
          Правила і умови
        </Link>
        <Link className='btn-business' to='/privacy_policy'>
          Умови конфіденційності
        </Link>
      </div>
    </footer>
  )
}

export default Footer
