import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Text, Button, Input, TextArea, colors } from '../../UIkit'
import { NetworkIcon } from '../../UIkit/svgs'
import { Validator } from '../../../lib'
import { handleValidate } from '../../../lib/validationHelper'
import { formatPhoneNumber } from '../../../lib'

const NetworkForm = ({ userData, createOwner }) => {

  const { t } = useTranslation()

  const [form, setForm] = useState({
    name: '',
    url: 'https://pinka.coffee',
    description: '',
    phone: '+380 (',
    email: '',
    iban: '',
    okpo: '',
    company: ''
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    description: '',
    phone: '',
    email: '',
    iban: '',
    okpo: '',
    company: ''
  })

  const validationFields = [
    ['name', Validator.concepts.isFilled, [form.name]],
    ['description', Validator.concepts.isFilled, [form.description]],
    ['phone', Validator.concepts.phoneRegex, [form.phone]],
    ['email', Validator.concepts.isFilled, [form.email]],
    ['iban', Validator.concepts.isFilled, [form.iban]],
    ['okpo', Validator.concepts.edrpouRegex, [form.okpo]],
    ['company', Validator.concepts.isFilled, [form.company]],
  ]

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  const handleUpdatePhone = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: formatPhoneNumber(target.value) }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      smsCode: userData.smsCode,
      owner: {
        email: userData.email,
        password: userData.password,
        passwordConfirmation: userData.passwordConfirmation,
        phoneNumber: userData.phoneNumber,
        profileAttributes: {
          firstName: userData.firstName,
          lastName: userData.lastName
        },
        networkAttributes: {
          name: form.name,
          url: form.url,
          description: form.description,
          liqpayAccountAttributes: {
            phone: form.phone,
            email: form.email,
            iban: form.iban,
            okpo: form.okpo,
            company: form.company,
            description: form.description,
            site: form.url,
            name: form.name
          }
        }
      }
    }

    handleValidate(validationFields)
      .then(() => createOwner(data))
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <form className='auth-page__form' onSubmit={handleSubmit}>
      <div className='auth-page__form__headline'>
        <NetworkIcon />
        <Text variant='subheading'>{t('signup:networkForm:title')}</Text>
      </div>
      <Input
        id='network-name'
        name='name'
        placeholder={t('input:networkName')}
        value={form.name}
        onChange={handleUpdateField}
      />
      <Input
        id='email'
        name='email'
        placeholder={t('input:email')}
        value={form.email}
        onChange={handleUpdateField}
        error={formErrors.email}
      />
      <Input
        id='phone'
        name='phone'
        placeholder={t('input:phone')}
        value={form.phone}
        onChange={handleUpdatePhone}
        error={formErrors.phone}
      />
      <Input
        id='iban'
        name='iban'
        placeholder={t('input:iban')}
        value={form.iban}
        onChange={handleUpdateField}
        error={formErrors.iban}
      />
      <Input
        id='company'
        name='company'
        placeholder={t('input:recipientBank')}
        value={form.company}
        onChange={handleUpdateField}
        error={formErrors.company}
      />
      <Input
        id='okpo'
        name='okpo'
        placeholder={t('input:edrpou')}
        value={form.okpo}
        onChange={handleUpdateField}
        error={formErrors.okpo}
      />
      <TextArea
        id='about'
        name='description'
        placeholder={t('input:about')}
        value={form.description}
        onChange={handleUpdateField}
        rows={8}
        error={formErrors.description}
      />
      <Button type='submit' disabled={form.name == ''}>
        {t('btn:signup')}
      </Button>
      <div className='auth-page__form__description'>
        <Text variant={'body-secondary1'} color={colors.gray[500]}>
          {t('signup:userForm:haveAccount')}&nbsp;
        </Text>
        <Text variant={'body-secondary1'}>
          <Link to='/admin'>{t('btn:signin')}</Link>
        </Text>
      </div>
    </form>
  )
}

NetworkForm.propTypes = {
  userData: PropTypes.object.isRequired,
  createNetwork: PropTypes.func.isRequired,
}

export default NetworkForm
