import React, { useState, useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import Tour from 'reactour'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'

import { PerPageContext } from '../contexts/perPageContext'
import { FiltersContext } from '../contexts/filtersContext'
import SideBarContainer from '../containers/SideBar'
import HeaderContainer from '../containers/Header'
import { countRowsPerPage } from '../lib'
import BottomBarContainer from '../containers/BottomBar/BottomBarContainer'
import LiqpayForm from './LiqpayForm'
import { liqpayAccountCreateRequest } from '../store/liqpayAccounts/actions'
import { tourSteps } from '../lib/tourSteps'
import { colors } from './UIkit'
import { profileUpdateRequest } from '../store/profiles/actions'

const AppAdmin = ({ children }) => {
  const dispatch = useDispatch()

  const [cityFilter, setCityFilter] = useState('')
  const [vendorFilter, setVendorFilter] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(countRowsPerPage())
  const [isOpen, setIsOpen] = useState(false)
  const { currentSession } = useSelector(({ authentication }) => authentication)
  const [showLiqpayForm, setShowLiqpayForm] = useState(false)
  const [tourOpened, setTourOpened] = useState(false)
  const [tourCurrentStep, setTourCurrentStep] = useState(0)

  const { steps, stepsLength } = tourSteps()

  const tutorialStates = [
    'dashboardTutorialCompleted',
  ]

  const createLiqpayAccount = (data) => dispatch(liqpayAccountCreateRequest(data, currentSession.networkId, closeForm ))

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  const closeForm = () => {
    setShowLiqpayForm(false)
  }

  const tutorialRoutes = [
    { route: '/dashboard', name: 'dashboardTutorialCompleted' },
  ]

  useEffect(() => {
    if (currentSession.liqpay_account_public_key === null || currentSession.liqpay_account_public_key === undefined) {
      setShowLiqpayForm(true)
    }
  }, [])

  useEffect(() => {
    !currentSession.tutorial_completed && tutorialStates.map((state) => fillTutorialStates(state))
  }, [currentSession])

  useEffect(() => {
    tutorialRoutes.map((item) => openTourForRoutes(item))
  }, [currentSession])

  const perPageContextValue = useMemo(
    () => ({
      rowsPerPage: rowsPerPage,
      setRowsPerPage: setRowsPerPage,
    }),
    [rowsPerPage]
  )

  const openTourForRoutes = (item) => {
    !currentSession.tutorial_completed
    && window.location.pathname === item.route
    && localStorage.getItem(item.name) === 'false'
    && setTourOpened(true)
  }

  const filtersContextValue = useMemo(
    () => ({
      cityFilter: cityFilter,
      setCityFilter: setCityFilter,
      vendorFilter: vendorFilter,
      setVendorFilter: setVendorFilter,
    }),
    [cityFilter, vendorFilter]
  )

  const enableScroll = target => enableBodyScroll(target)
  const disableScroll = target => disableBodyScroll(target)
  const handleCloseTour = () => {
    if (tourCurrentStep + 1 === stepsLength) {
      const data = {
        owner: {
          profileAttributes: {
            tutorialCompleted: true
          }
        }
      }

      window.location.pathname === '/dashboard' && localStorage.setItem('dashboardTutorialCompleted', 'true')
      dispatch(profileUpdateRequest(data))
      setTourOpened(false)
    }
  }

  const fillTutorialStates = (item) => {
    localStorage.getItem(item) === null && localStorage.setItem(item, 'false')
  }

  return (
    <PerPageContext.Provider value={perPageContextValue}>
      <FiltersContext.Provider value={filtersContextValue}>
        <div className='root-wrapper'>
          {showLiqpayForm ? null : <SideBarContainer isOpen={isOpen} onClose={toggleSidebar} /> }
          <div className={showLiqpayForm ? 'main-container without-sidebar' : 'main-container'}>
            {showLiqpayForm
              ? <LiqpayForm createLiqpayAccount={createLiqpayAccount} />
              : <>
                <HeaderContainer toggleSidebar={toggleSidebar} />
                {children}
                {isMobile && <BottomBarContainer/>}
              </>
            }
          </div>
          <Tour
            accentColor={colors.primary[500]}
            steps={steps}
            showNumber={false}
            onRequestClose={handleCloseTour}
            isOpen={tourOpened}
            rounded={13}
            onAfterOpen={disableScroll}
            onBeforeClose={enableScroll}
            disableInteraction={true}
            getCurrentStep={(curr) => setTourCurrentStep(curr)}
          />
        </div>
      </FiltersContext.Provider>
    </PerPageContext.Provider>
  )
}

export default AppAdmin
