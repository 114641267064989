import React from 'react'
import { useDispatch } from 'react-redux'

import LandingBusinessComponent from '../../components/LandingBusiness'
import { consultationCreateRequest } from '../../store/consultations/actions'

const LandingBusinessContainer = () => {
  const dispatch = useDispatch()
  const createConsultation = (data) => {
    dispatch(consultationCreateRequest(data))
  }

  return (
    <LandingBusinessComponent createConsultation={createConsultation} />
  )
}

export default LandingBusinessContainer
