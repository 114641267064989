import { takeLatest, call, put } from 'redux-saga/effects'
import * as actions from './actions'

import Api from '../../../lib/api'
import notifications from '../../../lib/notifications'
import { toCamelCase } from '../../../lib/converter'

export default function* watcherSaga() {
  yield takeLatest(actions.STAFF_NETWORKS_FETCH_REQUEST, watchResourcesFetchRequest),
  yield takeLatest(actions.STAFF_NETWORKS_GET_REQUEST, watchResourcesGetRequest)
  yield takeLatest(actions.STAFF_NETWORKS_UPDATE_REQUEST, watchResourcesUpdateRequest)
}

function* watchResourcesFetchRequest({ payload }) {
  try {
    const response = yield call(Api.Staff.Networks.fetch, payload.params)

    yield put(actions.staffNetworksFetchSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffNetworksFetchFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}

function* watchResourcesGetRequest({ id }) {
  try {
    const response = yield call(Api.Staff.Networks.get, id)

    yield put(actions.staffNetworksGetSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffNetworksGetFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}

function* watchResourcesUpdateRequest({ payload }) {
  try {
    const response = yield call(Api.Staff.Networks.update, payload.id, payload.params)

    yield put(actions.staffNetworksUpdateSuccess(toCamelCase(response?.data)))
  } catch (error) {
    yield put(actions.staffNetworksUpdateFailure(error))
    notifications.createNotification('error', error.response?.data)
  }
}
