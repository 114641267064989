import React, { useState } from 'react'

import './styles.scss'
import './responsiveStyles.scss'
import { Button, Input } from '../../../UIkit'
import { formatPhoneNumber, Validator } from '../../../../lib'
import { handleValidate } from '../../../../lib/validationHelper'
import { Illustration } from '../../../../assets/images/landing/Consultation'

const Consultation = ({ createConsultation }) => {
  const [form, setForm] = useState({
    name: '',
    phone: '+380',
    vendorName: ''
  })

  const [formErrors, setFormErrors] = useState({
    name: '',
    phone: '',
    vendorName: ''
  })

  const validationFields = [
    ['name', Validator.concepts.isFilled, [form.name]],
    ['phone', Validator.concepts.phoneRegex, [form.phone]],
    ['vendorName', Validator.concepts.isFilled, [form.vendorName]]
  ]

  const handleUpdateField = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: target.value }))
  }

  const handleUpdatePhone = ({ target }) => {
    setForm((prev) => ({ ...prev, [target.name]: formatPhoneNumber(target.value) }))
  }

  const clearForm = () => {
    setForm({
      name: '',
      phone: '+380',
      vendorName: ''
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      consultation: {
        name: form.name,
        phone: form.phone,
        vendorName: form.vendorName
      }
    }

    handleValidate(validationFields)
      .then(() => createConsultation(data))
      .then(() => clearForm())
      .catch((errors) => setFormErrors(errors))
  }

  return (
    <section id='consultation' className='consultation-section'>
      <h1>Замовити консультацію</h1>
      <form className='form' onSubmit={handleSubmit}>
        <div className='section-wrapper'>
          <div className='left-section'>
            <img src={Illustration} alt='Illustration'/>
          </div>
          <div className='right-section'>
            <div className='inputs'>
              <Input
                id='consultation-name'
                name='name'
                placeholder="Ім'я"
                value={form.name}
                onChange={handleUpdateField}
                error={formErrors.name}
              />
              <Input
                id='consultation-phone'
                name='phone'
                placeholder='Номер телефону'
                value={form.phone}
                onChange={handleUpdatePhone}
                error={formErrors.phone}
              />
              <Input
                id='consultation-vendor-name'
                name='vendorName'
                placeholder="Назвава кав'ярні"
                value={form.vendorName}
                onChange={handleUpdateField}
                error={formErrors.vendorName}
              />
            </div>
            <Button type='submit'>
              Відправити
            </Button>
          </div>
        </div>
      </form>
    </section>
  )
}

export default Consultation
